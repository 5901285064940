/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "./assets/scss/style-bootstrap.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

.ng-select.custom {
  background-color: #f3f3ff;
}

.ng-select .ng-select-container {
  min-height: 45px;
  border-color: #efefef;
}

.ng-select.custom.ng-select-single .ng-select-container {
  height: 45px;
  background-color: #f3f3ff;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0;
}

/* Border Color */
.ng-select.ng-invalid.ng-touched .ng-select-container {
  border: 1px solid;
  border-color: red;
}

/* Arrow Color */
.ng-select.ng-invalid.ng-touched .ng-arrow-wrapper .ng-arrow {
  border-color: red transparent transparent;
}

/* Placeholder Color */
.ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder {
  color: red;
}

.required-asterisk {
  color: red;
}

input.ng-invalid.ng-touched {
  border: 1px solid #e74c3c !important;
}

input.ng-valid.ng-touched {
  // Esse campo deixa as bordas verdes caso o campo receba o foco e depois desfoco e esteja válido
  // border: 1px solid #00ca0a !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ngb-pagination {
  font-weight: 300;
  font-size: 13px;
}

.h-livre {
  height: unset !important;
}

.button-height {
  height: 45px;
}

//-------------- Cursor --------------
//------------------------------------
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.cursor-default {
  cursor: default;
}
.cursor-progress {
  cursor: progress;
}
.cursor-not-allowed {
  cursor: not-allowed;
}